.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.more-heading {
  font-size: 2rem !important;
  // font-family: 'Mulish', sans-serif !important;
  // font-weight: 800 !important;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 270px;
  flex-direction: column;

  margin: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #000;

  cursor: pointer;
  transition: all 0.3s ease;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}