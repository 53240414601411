.app-head {
    font-size: 2.75rem;
    font-weight: bold;
    font-family: 'Eczar', serif;

}

.app-p {
    font-family: 'Mulish', sans-serif;

}